<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页><span>新闻</span></div>
		</div>
		<!--  -->
		<div class="NewsDetails">
			<div class="NewsDetails_cont">
				<div class="NewsDetails_cont_title">
					<h1>长风破浪廿四载，高歌猛进赢未来——热烈庆祝瓯文集团成立24周年！</h1>
					<div class="NewsDetails_cont_title_span">
						<img src="../../assets/img/newsDetail/矩形.png" alt="" /><span
							>发布时间：2023-06-06&ensp;&ensp;
						</span>
						<img src="../../assets/img/newsDetail/矩形(1).png" alt="" /><span
							>浏览次数：23
						</span>
					</div>
				</div>
				<div class="NewsDetails_cont_text">
					<p>
						2023年6月6日，蓝天丽日下，瓯文集团邕宁总部基地大红灯笼引人注目，彩旗迎风招展，烘托出热烈喜庆的气氛，喜迎瓯文集团成立24周年！
					</p>
					<div>瓯文精神，始终如一</div>
					<p>

						瓯文集团24周年司庆会议在集团总裁朱方文慷慨激昂的致辞中拉开了序幕。
					</p>
					<p>
						朱方文指出，24年前的今天，瓯文集团依托中尧路一个小门店正式成立，“顽强不屈、坚忍不拔”的瓯文精神就此扎根。历经24年的风风雨雨，从最初一个不到10平米的小门面到拥有50亩土地的独立产业园区；从不足10人的团队到千人规模的集团性企业；从默默无闻的小企业再到具有地方名片著称的品牌，每一次的决策与巨变，都是对瓯文人的胆识、智慧、勇气、魄力和毅力的巨大挑战与考验，如今的成绩都是全体瓯文人拼出来、干出来、奋斗出来的。
					</p>
					<p>
						朱方文强调，24年来，公司克服了人才短缺、资金匮乏、同行打压等种种困难，存活下来证明了一件事，只要瓯文人始终站在一起、想在一起、干在一起，任何风浪都动摇不了我们的钢铁意志。24年后的今天，瓯文依旧傲然屹立在广西，足以证明是没有任何力量能够撼动瓯文的地位，没有任何力量能够阻挡全体瓯文人前进的步伐。前进征程上，瓯文的今天正在全体瓯文人手中创造，我们要发扬瓯文人攻坚克难、苦干实干的奋斗者精神，不畏惧、不气馁、不退缩，瓯文的明天必将更加美好。
					</p>
					<div>山川异域，共庆喜悦</div>
					<p>当天，除集团管理者与获奖员工参加周年庆会议外，还通过网络平台发布“24周年司庆
					视频”，全体员工统一在线观看，各分子公司，市外办公点纷纷装扮办公场所，共庆喜悦。
					</p>
					<div>二十四年如一日</div>
					<p>
					瓯文集团深耕医疗器械领域，立足广西市场，加快国内步伐，拥有丰富的产品线和行业资源以及极高的行业敏感度。
					</p>
					<p>
					随着全球经济放缓和内生动力不强的国内经济环境改变，瓯文集团迅速调整战略布局，深入推进组织变革与创新，瓯文集团在“医疗流通、家庭医疗、制造产业”三大产业板块格局的助攻下，进入高质量发展阶段，并且利用自身优势长期坚持以市场为导向，不断提升企业市场综合竞争力，逐渐成为广西乃至全国更专业、更全面的医疗器械行业企业，引领行业的发展方向。
					</p>
					<p>
					过去是历史，希望在未来!瓯文人靠实干创造了辉煌的过去，还要靠实干开创更加美好的未来。瓯文人前进的脚步不会停止，奋进拼搏的精神不会停歇，前进的道路上再接再厉，继续披荆斩棘，再创辉煌。
					</p>
				</div>
				<!-- <div class="NewsDetails_cont_img">
					<img
						src="../../assets/img/newsDetail/摄图网_401521307_banner.png"
						alt=""
					/>
				</div> -->
				<Next ref="childData"></Next>
				<!-- <div class="NewsDetails_cont_btm">
					<div>相关新闻</div>
					<p>更多 ></p>
				</div> -->
				<!-- <div class="NewsDetails_cont_btm2">
					<div class="NewsDetails_cont_btm2_1">
						<div>发展数字经济 共享包容增长 为经济发展插上“数字翅膀”</div>
						<p>2021-05-30</p>
						<p>
							中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...
						</p>
					</div>
					<div class="NewsDetails_cont_btm2_1" style="background: #f8f8f8">
						<div>发展数字经济 共享包容增长 为经济发展插上“数字翅膀”</div>
						<p>2021-05-30</p>
						<p>
							中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...
						</p>
					</div>
				</div> -->
			</div>
		</div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
import Next from "../../components/NextLastArtcle.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
		Next,
	},
	data() {
		return {
			h1: "企业新闻",
			Img1: require("../../assets/img/keji/矿山banner1.png"),
			artcleID: 1,
			p1: "更多最新精彩资讯，感受企业魅力",
		};
	},
	methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 新闻详情 */
.NewsDetails_cont {
	width: 1200px;
	margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #2b2b2b;
	line-height: 33px;
	margin-top: 78px;
	text-align: center;
}
.NewsDetails_cont_title img {
	vertical-align: middle;
}
.NewsDetails_cont_title_span {
	text-align: center;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #a3a3a3;
	line-height: 30px;
	margin-top: 8px;
}
.NewsDetails_cont_text {
	margin-top: 40px;
	border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
	line-height: 30px;
	width: 1000px;
	margin: 40px 100px;
}
.NewsDetails_cont_text div {
	margin-left: 90px;
	font-size: 24px;
	font-weight: bold;
}
.NewsDetails_cont_img {
	text-align: center;
	margin-bottom: 40px;
}
.NewsDetails_cont_footer {
	padding: 0 40px;

	line-height: 68px;
	width: 1120px;
	height: 68px;
	background: #f8f8f8;
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm div {
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
}
.NewsDetails_cont_btm p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 20px;
}
.NewsDetails_cont_btm2 {
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
	margin: 32px 0;
	padding: 31px 61px 31px 20px;
	width: 507px;
	height: 123px;
	background: #ffffff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 28px;
}
</style>
