<template>
  <!-- 新闻内容 -->
  <div class="news_cont_zong">
    <div class="item2">
      <div class="item2_cont">
        <div
          class="item2_cont1"
          v-for="(item, index) in arrList"
          :key="index"
          @click="funUrl(index)"
        >
          <!-- <img :src="item.url" alt="" /> -->
          <div class="item2_cont1_text">
            <div class="item2_cont1_text_top">
              <h1>
                {{ item.h1 }}
              </h1>
              <p>
                {{ item.p1 }}
              </p>
            </div>
            <div class="item2_cont1_text_btm">
              <div>{{item.time}}</div>
              <p>查看更多></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      arrList: [
        {

          url: require("../../assets/img/youjiao/摄图网_501125646_banner_在草地上玩耍的孩子们（非企业商用）@2x.png"),
          h1: "“安全用械，共享健康”",
          p1: "7月13日上午，2023年“全国医疗器械安全宣传周”广西南宁市系列活动启动仪式在青秀万达广场室内中庭举行。",
        },
        {

          url: require("../../assets/img/youjiao/摄图网_501111552_banner_幼儿园老师指导画画（非企业商用）.png"),
          h1: "长风破浪廿四载，高歌猛进赢未来——热烈庆祝瓯文集团成立24周年！",
          p1: "2023年6月6日，蓝天丽日下，瓯文集团邕宁总部基地大红灯笼引人注目，彩旗迎风招展，烘托出热烈喜庆的气氛，喜迎瓯文集团成立24周年...",
        },
        {

          url: require("../../assets/img/youjiao/摄图网_501111552_banner_幼儿园老师指导画画（非企业商用）.png"),
          h1: "“宁”心聚力，合作共赢”",
          p1: "为增强公司凝聚力，2023年6月10日，广西瓯宁医疗科技有限公司举行了“团结协作，启航新征程”的团建活动...",
        },
        // {

        //   url: require("../../assets/img/youjiao/摄图网_501111552_banner_幼儿园老师指导画画（非企业商用）.png"),
        //   h1: "用系统思维下好“职业教育一盘大棋”",
        //   p1: "党的十九届五中全会审议通过的《中共中央关于制定国民经济和社会发展第十四个五年规划和二○三五远景目标的建议》内含了多条逻辑线...",
        // },
        // {

        //   url: require("../../assets/img/youjiao/摄图网_501111552_banner_幼儿园老师指导画画（非企业商用）.png"),
        //   h1: "用系统思维下好“职业教育一盘大棋”",
        //   p1: "党的十九届五中全会审议通过的《中共中央关于制定国民经济和社会发展第十四个五年规划和二○三五远景目标的建议》内含了多条逻辑线...",
        // },
      ],
    };
  },
  methods: {
    funUrl(index) {
      if (index < 3) {
        this.$router.push("/newsxiangqing" + index);
      } else {
        alert("页面未创建无法跳转");
      }
    },
  },
};
</script>

<style scoped>
/* 新闻列表 */
.item_cont {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
.item2_cont1:first-child {
  display: flex;
  width: 1200px;
  height: 252px;
  background: #ffffff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

.item_cont1 img {
  margin: 32px 48px 32px 32px;
  width: 300px;
  height: 188px;
  border: dashed;
}
.item_cont1_text {
  width: 868px;
}
.item_cont1_text_top {
  margin: 45px 0 16px 0;
  width: 732px;
  height: 112px;

  border-bottom: 1px solid #eaeaea;
}
.item_cont1_text_top > h1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 24px;
}
.item_cont1_text_top p {
  margin-top: 16px;
  width: 732px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.item_cont1_text_btm {
  width: 732px;

  display: flex;
  justify-content: space-between;
}
.item_cont1_text_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}
.item_cont1_text_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}
/* item2 */
.item2_cont {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 30px;
}
.item2_cont1 {
  margin-top: 30px;
  display: flex;
  width: 1200px;
  height: 252px;

  background: #f8f8f8;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

.item2_cont1 img {
  margin: 32px 48px 32px 32px;
  width: 300px;
  height: 188px;
  border: dashed;
}
.item2_cont1_text {
  width: 1200px;
}
.item2_cont1_text_top {
  margin: 45px 0 16px 0;
  width: 1200px;
  height: 112px;

  border-bottom: 1px solid #eaeaea;
}
.item2_cont1_text_top > h1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;

  color: #3f3f3f;
  line-height: 24px;
}
.item2_cont1_text_top p {
  margin-top: 16px;
  width: 1200px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.item2_cont1_text_btm {
  width: 1100px;

  display: flex;
  justify-content: space-between;
}
.item2_cont1_text_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;

  color: #999999;
  line-height: 28px;
}
.item2_cont1_text_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}
</style>