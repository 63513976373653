<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页><span>金融</span></div>
		</div>
		<!-- 金融板块 -->
		<div class="Money">
			<div class="Money_cont">
				<h1>金融板块</h1>
				<div class="Money_cont_top">
					<p>
						金融板块坚持“协同合作、助推发展、服务社会”的经营理念和“专业化、职业化、精英化、精细化”的人才管理理念，建立规范的投资操作流程和完善的风险控制体系，吸纳了一支具有金融、管理、法律、财务等经济领域的精英人才团队，凭借其丰富的行业经验和敏锐的行业洞察力，深度运营旗下各类金融产品，为广大客户提供全面、丰富、创新和个性化的金融产品与服务，制定全方位、专业的金融解决方案。<br />
						金融板块借力雄厚的资金实力、丰富的政企资源以及各领域众多资深合作伙伴，主要从事融资租赁、信托、产业基金、债权融资等业务。目前，通过融资规划及联合租赁等方式促成的合作已累计超过150亿元，受客户和资方委托管理标的物及资产达170亿元。保障所有资金落地、资产管理安全使我们在业界赢得了良好的信誉口碑。
					</p>
					<img
						src="../assets/img/jinrong/摄图网_500559818_banner_现代化金融城市（非企业商用）.png"
						alt=""
					/>
				</div>
				<div class="Money_cont_c">
					<div class="Money_cont_c_img">
						<img src="../assets/img/jinrong/编组 2(1).png" alt="" />
						<p>融资主频</p>
					</div>
					<div class="Money_cont_c_text">
						<p>
							金融板块依托强大的产业背景、雄厚的资金实力、丰富的政企资源以及各领域众多资深合作伙伴，借助灵活的资本运作方式以及集团采购、整体打包的资源优势，通过融资租赁、直接租赁、售后回租、厂商租赁、风险租赁等业务方式，为政企客户提供全方位、专业化、一站式的金融服务。
						</p>
						<div class="Money_cont_c_text_b">
							<span>01</span>
							<p>
								根据承租人的要求，向承租人指定的供货商购买指定的设备，并将该设备的占有、使用和收益权转让给承租人
							</p>
						</div>
						<div class="Money_cont_c_text_b">
							<span>01</span>
							<p>
								根据承租人的要求，向承租人指定的供货商购买指定的设备，并将该设备的占有、使用和收益权转让给承租人
							</p>
						</div>
						<div class="Money_cont_c_text_b">
							<span>01</span>
							<p>
								根据承租人的要求，向承租人指定的供货商购买指定的设备，并将该设备的占有、使用和收益权转让给承租人
							</p>
						</div>
					</div>
				</div>
				<div class="Money_cont_b">
					<div class="Money_cont_b_item1">
						<div class="Money_cont_b_img">
							<img src="../assets/img/jinrong/编组 2(1).png" alt="" />
							<p>融资主频</p>
						</div>
						<div class="Money_cont_b_text">
							<p>
								信托即信用委托，是指委托人基于对受托人的信任，将其财产权委托给受托人，由受托人按委托人的意愿以自己的名义，为受益人的利益或者特定目的进行管理或者处分的行为。<br />
								金融板块始终紧跟国家发展战略，通过资金信托、动产信托、不动产信托、有价证券信托等多种形式，经营企业资产的重组、购并及项目融资、公司理财等，拓展全新业务增长极，推动业务回归本源，打造出金融助力实体经济发展的信托范本。
							</p>
						</div>
					</div>
					<div class="Money_cont_b_item2">
						<div class="Money_cont_b_item2_img">
							<img src="../assets/img/jinrong/编组 2(1).png" alt="" />
							<p>融资主频</p>
						</div>
						<div class="Money_cont_b_item2_text">
							<p>
								信托即信用委托，是指委托人基于对受托人的信任，将其财产权委托给受托人，由受托人按委托人的意愿以自己的名义，为受益人的利益或者特定目的进行管理或者处分的行为。<br />
								金融板块始终紧跟国家发展战略，通过资金信托、动产信托、不动产信托、有价证券信托等多种形式，经营企业资产的重组、购并及项目融资、公司理财等，拓展全新业务增长极，推动业务回归本源，打造出金融助力实体经济发展的信托范本。
							</p>
						</div>
					</div>
					<div class="Money_cont_b_item3 Money_cont_b_item4">
						<div class="Money_cont_b_item3_img">
							<img src="../assets/img/jinrong/编组 2.png" alt="" />
							<p>融资主频</p>
						</div>
						<div class="Money_cont_b_item3_text">
							<p>
								信托即信用委托，是指委托人基于对受托人的信任，将其财产权委托给受托人，由受托人按委托人的意愿以自己的名义，为受益人的利益或者特定目的进行管理或者处分的行为。<br />
								金融板块始终紧跟国家发展战略，通过资金信托、动产信托、不动产信托、有价证券信托等多种形式，经营企业资产的重组、购并及项目融资、公司理财等，拓展全新业务增长极，推动业务回归本源，打造出金融助力实体经济发展的信托范本。
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			h1: "金融产业 · 集团产业",
			Img1: require("../assets/img/keji/矿山banner1.png"),
		};
	},
	methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 金融 */
.Money_cont {
	width: 1200px;
	margin: 0 auto;
}
.Money_cont > h1 {
	margin: 65px 0 45px 0;
	text-align: center;
}
.Money_cont_top {
	display: flex;
	justify-content: space-between;
}
.Money_cont_top > p {
	width: 600px;
	height: 360px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 40px;
}
/* 中 */
.Money_cont_c {
	display: flex;
	margin-top: 80px;
	width: 1200px;
	height: 440px;
}
.Money_cont_c_img {
	text-align: center;

	width: 322px;
	height: 440px;
	background-image: url("../assets/img/jinrong/摄图网_500476224_banner_看得见的未来（非企业商用）.png");
}
.Money_cont_c_img p {
	margin-top: 32px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #ffffff;
	line-height: 33px;
}
.Money_cont_c_img img {
	margin-top: 138px;
}
.Money_cont_c_text {
	width: 878px;
	height: 440px;
	background: #f8f8f8;
}
.Money_cont_c_text > p {
	width: 769px;
	height: 96px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 32px;
	margin: 40px 0 40px 40px;
}
.Money_cont_c_text_b {
	margin-bottom: 30px;
	margin-left: 40px;
}
.Money_cont_c_text_b span {
	color: blue;
}
.Money_cont_b_item1 {
	display: flex;
	margin-top: 40px;
}
.Money_cont_b_img {
	text-align: center;
	background-image: url("../assets/img/jinrong/摄图网_500476224_banner_看得见的未来（非企业商用）.png");
	width: 322px;
	height: 300px;
}
.Money_cont_b_img p {
	margin-top: 32px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #ffffff;
	line-height: 33px;
}
.Money_cont_b_img img {
	margin-top: 67px;
}
.Money_cont_b_text {
	width: 878px;
	height: 300px;
	background: #f8f8f8;
}
.Money_cont_b_text p {
	margin: 50px 0 0 40px;
	width: 769px;
	height: 200px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 40px;
}
/* item2 */
.Money_cont_b_item2 {
	display: flex;
	margin-top: 40px;
}
.Money_cont_b_item2_img {
	text-align: center;
	background-image: url("../assets/img/jinrong/摄图网_500476224_banner_看得见的未来（非企业商用）.png");
	width: 322px;
	height: 300px;
}
.Money_cont_b_item2_img p {
	margin-top: 32px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #ffffff;
	line-height: 33px;
}
.Money_cont_b_item2_img img {
	margin-top: 67px;
}
.Money_cont_b_item2_text {
	width: 878px;
	height: 300px;
	background: #f8f8f8;
}
.Money_cont_b_item2_text p {
	margin: 50px 0 0 40px;
	width: 769px;
	height: 200px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 40px;
}

/* item3 */
.Money_cont_b_item3 {
	display: flex;

	margin: 40px 0 80px 0;
}
.Money_cont_b_item3_img {
	text-align: center;
	background-image: url("../assets/img/jinrong/摄图网_401914220_banner_3D创意金融（非企业商用）.png");
	width: 322px;
	height: 300px;
}
.Money_cont_b_item3_img p {
	margin-top: 32px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #ffffff;
	line-height: 33px;
}
.Money_cont_b_item3_img img {
	margin-top: 67px;
}
.Money_cont_b_item3_text {
	width: 878px;
	height: 300px;
	background: #f8f8f8;
}
.Money_cont_b_item3_text p {
	margin: 50px 0 0 40px;
	width: 769px;
	height: 200px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 40px;
}
</style>
