<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页><span>新闻</span></div>
		</div>
		<!--  -->
		<div class="NewsDetails">
			<div class="NewsDetails_cont">
				<div class="NewsDetails_cont_title">
					<h1>“安全用械，共享健康”——南宁市2023年医疗器械安全宣传周启动</h1>
					<div class="NewsDetails_cont_title_span">
						<img src="../assets/img/newsDetail/矩形.png" alt="" /><span
							>发布时间：2023-7-13&ensp;&ensp;
						</span>
						<img src="../assets/img/newsDetail/矩形(1).png" alt="" /><span
							>浏览次数：12
						</span>
					</div>
				</div>
				<div class="NewsDetails_cont_text">
					<p>
						以下文章来源于南宁市医疗器械行业协会 ，作者秘书处
						7月13日上午，2023年“全国医疗器械安全宣传周”广西南宁市系列活动启动仪式在青秀万达广场室内中庭举行。本次活动以“安全用械，共享健康”为主题，进一步贯彻落实医疗器械监管相关法律法规，宣传医疗器械监管新政策，强化企业质量安全主体责任，提升公众对医疗器械安全使用的认知水平，引导广大消费者科学合理使用医疗器械，保障公众用械安全。<br>
						南宁市医疗器械行业协会作为协办单位，由常务副会长兼秘书长莫凡同志带领南宁市内医疗器械经营企业、使用单位共18名代表登台宣读“诚信经营 安全用械”承诺书。<br>

						南宁市医疗器械行业协会还在现场提供了咨询台，科普医疗器械知识及用械安全知识，提醒市民选购家用医疗器械时要牢牢把握以下五个要点：<br>

						一是在医生指导下购买；<br>

						二是学会查看产品证照；<br>

						三要到有经营资质的商家选购；<br>

						四是不要轻信推销人员的宣传；<br>

						五是注意分辨普通商品和医疗器械。<br>
						南宁市医疗器械行业协会成立于2022年12月，目前有单位成员80名。2023年，将把工作重心放在经营企业和使用单位上，树立行业、政府、协会“三位一体”的理念，在“安全用械，共享健康”上下功夫，扩大会员数量。并及时制定出相应的行规行约，完善自律机制建设，从而起到规范行业内部的生产、经营和使用行为；坚持质量第一，生产、销售和使用合规的医疗器械产品；要严格遵循市场规律，避免不规范竞争行为发生，最终形成一股合力，共同推动南宁市医疗器械行业健康可持续发展。<br>



					</p>


				</div>
				<!-- <div class="NewsDetails_cont_img">
					<img
						src="../assets/img/newsDetail/摄图网_401521307_banner.png"
						alt=""
					/>
				</div> -->
				<!-- 上一篇 下一篇 -->
				<Next ref="childData"></Next>
				<!-- <div class="NewsDetails_cont_btm">
					<div>相关新闻</div>
					<p>更多 ></p>
				</div>
				<div class="NewsDetails_cont_btm2">
					<div class="NewsDetails_cont_btm2_1">
						<div>发展数字经济 共享包容增长 为经济发展插上“数字翅膀”</div>
						<p>2021-05-30</p>
						<p>
							中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...
						</p>
					</div>
					<div class="NewsDetails_cont_btm2_1" style="background: #f8f8f8">
						<div>发展数字经济 共享包容增长 为经济发展插上“数字翅膀”</div>
						<p>2021-05-30</p>
						<p>
							中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...
						</p>
					</div>
				</div> -->
			</div>
		</div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import Next from "../components/NextLastArtcle.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
		Next,
	},
	data() {
		return {
			h1: "企业新闻",
			Img1: require("../assets/img/keji/矿山banner1.png"),
			// 这是我在父的组件里 定义的变量 表示当前是数组里第1个页面
			artcleID: 0,

			p1: "更多最新精彩资讯，感受企业魅力",
		};
	},

	methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 新闻详情 */
.NewsDetails_cont {
	width: 1200px;
	margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #2b2b2b;
	line-height: 33px;
	margin-top: 78px;
	text-align: center;
}
.NewsDetails_cont_title img {
	vertical-align: middle;
}
.NewsDetails_cont_title_span {
	text-align: center;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #a3a3a3;
	line-height: 30px;
	margin-top: 8px;
}
.NewsDetails_cont_text {
	margin-top: 40px;
	border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
	line-height: 30px;
	width: 1000px;
	margin: 40px 100px;
}
.NewsDetails_cont_text div {
	margin-left: 90px;
	font-size: 24px;
	font-weight: bold;
}
.NewsDetails_cont_img {
	text-align: center;
	margin-bottom: 40px;
}

.NewsDetails_cont_btm {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm div {
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
}
.NewsDetails_cont_btm p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 20px;
}
.NewsDetails_cont_btm2 {
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
	margin: 32px 0;
	padding: 31px 61px 31px 20px;
	width: 507px;
	height: 123px;
	background: #ffffff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 28px;
}
</style>
