<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页><span>新闻</span></div>
		</div>
		<!--  -->
		<div class="NewsDetails">
			<div class="NewsDetails_cont">
				<div class="NewsDetails_cont_title">
					<h1>“宁”心聚力，合作共赢，广西瓯宁医疗科技有限公司团建取得圆满成功！</h1>
					<div class="NewsDetails_cont_title_span">
						<img src="../../assets/img/newsDetail/矩形.png" alt="" /><span
							>发布时间：2023-06-10&ensp;&ensp;
						</span>
						<img src="../../assets/img/newsDetail/矩形(1).png" alt="" /><span
							>浏览次数：30
						</span>
					</div>
				</div>
				<div class="NewsDetails_cont_text">
						<div>
							“宁”心聚力，合作共赢，广西瓯宁医疗科技有限公司团建取得圆满成功！
						</div>
						<p>

							为增强公司凝聚力，2023年6月10日，广西瓯宁医疗科技有限公司举行了“团结协作，启航新征程”的团建活动。本次团建主要以销售地推+团队游戏的形式，让员工在紧张的工作之余得到放松，在热情氛围中让新员工迅速融入团队。
						</p>
						<div>
							1、实战演练，练就真功夫！
						</div>
						<p>
							快速抢占市场，锻炼公司员工销售意识和技能，提升销售团队的自信，在短暂的规则讲解后，以老带新，大家很快兵分几路开展为期一上午的“销售地推”演练。
						</p>
						<p>
							瓯宁的销售将士们在战场上，以瓯宁的优质产品破局，走街串巷，集思广义将体温计、湿厕纸等产品推荐并卖出去。各小组积极参与，发挥个人优势，顺利完成销售任务的同时，也提升了公司内部凝聚力和个人营销水平。
						</p>
						<div>
							2、聚力献策，勇夺第一名
						</div>
						<p>
							一上午的销售演练收获满满，大家不觉疲累，反而越战越勇。下午的团队游戏一开场即是高潮，员工们按照游戏规则被分为三支队伍，接下来青蛙过河、翻牌找数字、看图猜顺序等游戏更是精彩又刺激。

						</p>
						<p>
							共赴一场游戏之旅，大家深深体会到团队的成功需要我们每一个成员的共同努力，从小组讨论、到决策执行再到合力通关，智慧与激情的火花在彼此脑海中碰撞。
						</p>
						<p>
							通过游戏，提高了团队成员的责任心和策略思考能力，在今后的工作中能够更好的适应团队协作的工作环境。
						</p>
						<div>
							3、宁心聚力，创美好未来
						</div>
						<p>
							哪怕最后一秒也不放弃希望的“瓯宁精神”，在此刻得到充分体现。这是一场愉悦又激昂的团队协作时间，员工们不仅学习到了收益颇多的销管知识和实战经验，更是通过精心策划的游戏，领略了团结的无上魅力。
								汗水源于感动，
								一个人的力量有限，
								但团队的力量却无穷无尽。
								为自己加油，为团队喝彩，
								为瓯宁而战，你是最棒的！
						</p>



				</div>
				<!-- <div class="NewsDetails_cont_img">
					<img
						src="../../assets/img/newsDetail/摄图网_401521307_banner.png"
						alt=""
					/>
				</div> -->
				<Next ref="childData"></Next>
				<!-- <div class="NewsDetails_cont_btm">
					<div>相关新闻</div>
					<p>更多 ></p>
				</div>
				<div class="NewsDetails_cont_btm2">
					<div class="NewsDetails_cont_btm2_1">
						<div>发展数字经济 共享包容增长 为经济发展插上“数字翅膀”</div>
						<p>2021-05-30</p>
						<p>
							中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...
						</p>
					</div>
					<div class="NewsDetails_cont_btm2_1" style="background: #f8f8f8">
						<div>发展数字经济 共享包容增长 为经济发展插上“数字翅膀”</div>
						<p>2021-05-30</p>
						<p>
							中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...
						</p>
					</div>
				</div> -->
			</div>
		</div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
import Next from "../../components/NextLastArtcle.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
		Next,
	},
	data() {
		return {
			h1: "企业新闻",
			Img1: require("../../assets/img/keji/矿山banner1.png"),
			artcleID: 2,
			p1: "更多最新精彩资讯，感受企业魅力",
		};
	},
	methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 新闻详情 */
.NewsDetails_cont {
	width: 1200px;
	margin: 0 auto;
}
.NewsDetails_cont_title > h1 {
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #2b2b2b;
	line-height: 33px;
	margin-top: 78px;
	text-align: center;
}
.NewsDetails_cont_title img {
	vertical-align: middle;
}
.NewsDetails_cont_title_span {
	text-align: center;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #a3a3a3;
	line-height: 30px;
	margin-top: 8px;
}
.NewsDetails_cont_text {
	margin-top: 40px;
	border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
	line-height: 30px;
	width: 1000px;
	margin: 40px 100px;
}
.NewsDetails_cont_text div {
	margin-left: 90px;
	font-size: 24px;
	font-weight: bold;
}
.NewsDetails_cont_img {
	text-align: center;
	margin-bottom: 40px;
}
.NewsDetails_cont_footer {
	padding: 0 40px;

	line-height: 68px;
	width: 1120px;
	height: 68px;
	background: #f8f8f8;
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm div {
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #222222;
	line-height: 33px;
}
.NewsDetails_cont_btm p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 20px;
}
.NewsDetails_cont_btm2 {
	display: flex;
	justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
	margin: 32px 0;
	padding: 31px 61px 31px 20px;
	width: 507px;
	height: 123px;
	background: #ffffff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 28px;
}
</style>
